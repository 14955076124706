import React, { Component } from 'react';
import { CategoriesMap } from './CategoriesMap';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        const { labels } = this.props;
        const text = labels['HomeMainText'].split('\n').map((x, i) => <p key={`${x} - ${i}`} style={{marginBottom:'5px'}}>{x}</p>)
        return (
            <div>
                <h1>{labels['HomeMainLabel']}</h1>
                {text}
                <CategoriesMap {...this.props} />
            </div>
        );
    }
}
