import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { SearchPopup } from './SearchPopup';

export class NavMenu extends Component {
    static displayName = NavMenu.name;
    state = {};
    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };

        
    }
    
    search = (e) => {
        this.props.searchSetText(e.target.value);
    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    componentDidMount() {
        const position = this.inputSearch.getBoundingClientRect();
        this.setState({ position: position });
    }

    render() {
        const { menu, contactInfo } = this.props;
        let screen = navigator.userAgent;
        let isMobile = screen.width < 670 ||
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPod/i);
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container className="top-container">
                        <div style={{display:'flex'}}>
                            <img style={{maxHeight:'155px', maxWidth:'250px'}} src="/content/images/logo.png"/>
                            <div><NavbarBrand tag={Link} to="/"><h2 className="usual-color"><strong>{menu['MainLabel']}</strong></h2></NavbarBrand>
                                <div style={{ display: 'flex' }}><input ref={ref => this.inputSearch= ref} value={this.props.searchText} className="search" type="text" onChange={this.search} />
                                    <i className="fa-search" style={{marginLeft:'-19px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className=" bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg></i></div>
                            </div>
                            {this.props.searchText && this.props.searchText.length > 3 ? <SearchPopup {...this.props} position={this.state.position} changeSearch={this.props.searchSetText} />:null}
                         {isMobile ? <a className="top-main-phone-mobile" href={`tel: ${ contactInfo['mainPhone']}`}><img style={{ maxHeight: '30px', maxWidth: '30px', marginBottom: '10px' }} src="/content/images/phone-icon.png"/></a> :

                                <a style={{ marginTop: '25px' }} className="top-main-phone" href="tel:{contactInfo['mainPhone']}">{contactInfo['mainPhone']}</a>}
                                
                            
                            </div>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex" isOpen={!this.state.collapsed} navbar>
                            <ol className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/"><strong>{menu['MenuName1']}</strong></NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/categories"><strong>{menu['MenuName2']}</strong></NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/contacts"><strong>{menu['MenuName3']}</strong></NavLink>
                                </NavItem>
                            </ol>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
