import React, { Component } from 'react';

export class CategoriesMap extends Component {

    constructor(props) {
        super(props);
        this.convertToArray = () => {
            const { labels, categories } = this.props;
            const arr = [];
            for (let prop in categories) {
                arr.push({ Key: prop, Value: categories[prop] });
            }
            return arr;
        }

        this.goTo = (category) => {
            this.props.history.push(`/catalog/${category}`);
        };
    }



    render() {
        const { labels, categories , searchText} = this.props;
        const filteredItems = /*searchText && searchText.length >= 3
            ? this.convertToArray().filter(x => x.Value.toLowerCase().indexOf(searchText.toLowerCase()) > -1) :*/ this.convertToArray();
        return (
            <div className="category-content">
                {filteredItems.map((x, i) =>

                    <div key={`${x.Key}-${i}`} className="box"><a onClick={() => this.goTo(x.Key)}><div className="img-div"><img style={{ maxHeight: '200px', maxWidth: '200px' }} src={`content/images/${x.Key.replace(/[0-9]/, '')}.jpg`} /></div><br /></a><a onClick={() => this.goTo(x.Key)}><strong>{x.Value}</strong></a></div>)}

                </div>
        );
    }
}
