import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Route } from 'react-router';
import { Home } from './Home';
import { Contacts } from './Contacts';
import { Categories } from './Categories';
import { Catalog } from './Catalog';
import { FullDescription } from './FullDescription';
import { CookieComponent } from './Cookie';
export class Layout extends Component {
   

    render() {
        if (!this.props.labels) return (null);
        return (
            <div>
                <NavMenu menu={this.props.labels} {...this.props} />
                <CookieComponent {...this.props} />
                <Container>
                    <Route exact path='/' render={props => <Home {...this.props} {...props} />} />
                    <Route path='/categories' render={props => <Categories {...this.props} {...props} />} />
                    <Route path='/contacts' render={props => <Contacts  {...this.props} {...props} />} />
                    <Route path={`/catalog/:category`} render={props => <Catalog {...this.props} {...props} />} />
                    <Route path={`/details/:category/:id`} render={props => <FullDescription {...this.props} {...props} />} />
                </Container>
            </div>
        );
    }
}
