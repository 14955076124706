import React, { Component } from 'react';
import { CategoriesMap } from './CategoriesMap';
import { Container } from 'reactstrap';

export class Categories extends Component {
    render() {
        const { labels, categories} = this.props;
        return (
            <div>
                <h1>{labels['Catalog']}</h1>
                <Container>
                    <CategoriesMap {...this.props} />
                </Container>
            </div>
        );
    }
}
