import React, { Component } from 'react';

import { Layout } from './Layout';


import './custom.css'

export default class App extends Component {
    state = {};
    searchSetText=(val) => {
        this.setState({searchText: val});
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData() {
        const response = await fetch('content');
        const data = await response.json();
        this.setState({ labels: data.labels, categories: data.categories, catalog: data.catalog, contactInfo: data.contactInfo });
    }
    render() {
        return (
            <Layout {...this.state} searchSetText={this.searchSetText}/>
        );
    }
}
