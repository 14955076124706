import React, { Component } from 'react';
import { Map, Marker } from 'yandex-map-react';
export class Contacts extends Component {

    constructor(props) {
        super(props);
        this.state = { lat: 53.635769, lon:27.930104 };
    }

    

   

    render() {
        const { labels, contactInfo } = this.props;
        return (
            <div>
                <div><strong>{labels['Address']}</strong></div>
                <ul>{contactInfo.address.split('\n').filter(p => p).map((s, i) => <li key={`${s}-${i}`}>{s}</li>)}</ul>
                <div><strong>{labels['otherPhoneNumber']}</strong></div>
                <ul>{contactInfo.otherPhones.split('\n').filter(p => p).map((s, i) => <li key={`${s}-${i}`}><a className="usual-phone" href={`tel:${s}`}>{s} </a></li>)}</ul>
                <div><strong>{labels['additionalInfo']}</strong></div>
                <ul>{contactInfo.additionalInfo.split('\n').filter(p => p).map((s, i) => <li key={`${s}-${i}`}>{s}</li>)}</ul>
                <Map onAPIAvailable={function () { }} center={[contactInfo.lattitude, contactInfo.longitude]} zoom={13}>
                    <Marker lat={contactInfo.lattitude} lon={contactInfo.longitude} />
                </Map>
            </div>
        );
    }
}
