import React, { Component } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { Table } from 'reactstrap';

export class FullDescription extends Component {

    constructor(props) {
        
        super(props);
        this.state = { descriptionActive: true };
        this.goTo = (category) => {
            this.props.history.push(`/catalog/${category}`);
        };

        this.convertToArrayNavLink = () => {
            const { categories, match } = this.props;
            const category = match.params.category;
            const arr = [];
            for (let prop in categories) {
                arr.push(<NavItem key={`/catalog/${prop}-nav`} className={`side-link ${category === prop ? 'active' : ''}`}>
                    <a onClick={() => this.goTo(prop)} className="nav-link "><b>{categories[`${prop}`]}</b></a>
                </NavItem>);
            }

            return arr;
        };

        this.getCurrentCategoryName = () => {
            const { labels, categories, match, catalog } = this.props;
            const category = match.params.category;
            const id = match.params.id;
            const item = catalog.find(x => x.id == id);
            return `${labels['Catalog']}-${categories[category.toString()]}${item ? ('-' + item.shortName) : ''}`;
        }

        this.changeTab = () => {
            this.setState({ descriptionActive: !this.state.descriptionActive });
        }

       
    }



    render() {
        const { labels, match, catalog } = this.props;
        const id = match.params.id;
        const item = catalog.find(x => x.id == id);
        const tabContent = this.state.descriptionActive
            ? item && item.notes ? item.notes.split('\n').filter(p => p).map((s, i) => <p key={`${s}-${i}`}>{s}</p>) : null
            : item && item.pricings ? item.pricings.split('\n').filter(p => p).map((s, i) => i === 0 ? <p key={`${s}-${i}`}><strong>{s}</strong></p> : <p key={`${s}-${i}`}>{s}</p>) : null;
        return (
            <div>
                <h2>{this.getCurrentCategoryName()}</h2>
                <div className="catalog-container">
                    <Table responsive borderless>
                        <tbody>
                            <tr>
                                <td className="td-nav">
                                    <Nav vertical className="categories-nav" style={{ maxHeight: `${68 * this.convertToArrayNavLink().length}px` }}>
                                        {this.convertToArrayNavLink()}
                                    </Nav>
                                </td>
                                <td><Table responsive borderless>
                                    <thead className="orange-background usual-color">
                                        <tr style={{ textAlign: 'center' }}>
                                        <th style={{width:'160px'}}>{labels['NameOfTool']}</th>
                                        <th style={{ width: '330px' }}>{labels['toolDescription']}</th>
                                        <th>{labels['toolPrice']}</th>
                                        <th>{labels['toolDeposit']}</th>
                                    </tr>
                                    </thead>
                                    <tbody><tr className="usual-color" style={{ textAlign: 'center' }}>
                                        <td style={{ width: '160px' }}>{item && item.name ? item.name.split('\n').filter(p => p).map((s, i) => <p key={`${s}-${i}`}>{s}</p>) : null}</td>
                                        <td style={{ width: '330px' }}>{item && item.shortDescription ? item.shortDescription.split('\n').filter(p => p).map((s, i) => <p key={`${s}-${i}`}>{s}</p>) : null}</td>
                                        <td>{item && item.pricings ? item.pricings.split('\n').filter(p => p).map((s, i) => <p key={`${s}-${i}`}>{s}</p>) : null}</td>
                                        <td>{item && item.deposit ? item.deposit.split('\n').filter(p => p).map((s, i) => <p key={`${s}-${i}`}>{s}</p>) : null}</td>
                                    </tr></tbody>
                                </Table></td>
                            {/*<td>
                                    <div className="description-name">
                                        <strong>{item ? item.name : null}</strong>
                                    </div>
                                    {item.image ?
                                        <div className="description-image">
                                            <img style={{ maxHeight: '300px', maxWidth: '375px' }} src={`/content/images/${item.image}`} />
                                        </div> : null}
                                    <div className="tab-wrap">
                                        <label className={`${this.state.descriptionActive ? 'label-active' : ''}`} onClick={this.changeTab}>{labels['description']}</label>

                                        <label className={`${!this.state.descriptionActive ? 'label-active' : ''}`} onClick={this.changeTab}>{labels['price']}</label>
                                        
                                           

                                        <div className="tab__content">{tabContent}</div>

                                    </div>
                                </td>*/}
                            </tr>
                        </tbody>
                    </Table>

                </div>
            </div>
        );
    }
}
