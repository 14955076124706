import React, { Component } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { Table } from 'reactstrap';
const cookieName = "renttoolmegaCookie";
export class CookieComponent extends Component {
    state = {};
    getCookie = () => {
        const value = " " + document.cookie;
        const parts = value.split(" " + cookieName + "=");
        return parts.length < 2 ? undefined : parts.pop().split(";").shift();
    };

    setCookie = (value, expiryDays=365, domain, path)=> {
        this.cookiesBanner.classList.add("hidden");
        const exdate = new Date();
        exdate.setHours(
            exdate.getHours() +
            (typeof expiryDays !== "number" ? 365 : expiryDays) * 24
        );
        document.cookie =
            cookieName +
            "=" +
            "Close" +
            ";expires=" +
            exdate.toUTCString() +
            ";path=" +
            (path || "/") +
            (domain ? ";domain=" + domain : "") +
            ";secure";
       
   };

    componentDidMount() {
        //this.cookiesBanner = document.querySelector(".cookies-eu-banner");
        const hasCookie = this.getCookie(cookieName);
        console.log(hasCookie);
        if (!hasCookie) {
            this.cookiesBanner.classList.remove("hidden");
        }
    }

    cancel = () => {
        this.cookiesBanner.classList.add("hidden");
    }
    render() {
        const { labels } = this.props;
        const element = this.state.showPolicyStuffs ?
            <div className="modal custom-background" id="exampleModal" style={{ display: 'block', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.4)' }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{labels.cookiePolicyHeader}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => { this.setState({ showPolicyStuffs: false }, () => { this.cookiesBanner.classList.remove("hidden"); });  }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body" style={{ maxHeight: '330px', overflowY: 'auto' }}>
                            {labels.coolkiePolicyText}
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => { this.setState({ showPolicyStuffs: false }, () => { this.cookiesBanner.classList.add("hidden"); }); }} type="button" className="btn btn-secondary " data-bs-dismiss="modal">{labels.cookieCancelButton}</button>
                            <button onClick={() => { this.setCookie(); this.setState({ showPolicyStuffs: false }); }} type="button" className="btn btn-primary agree">{labels.cookieAcceptButton}</button>
                        </div>
                    </div>
                </div>
            </div> : <div ref={(ref) => this.cookiesBanner = ref} className="cookies-eu-banner hidden">
                {labels.cookieShortInfo} <a className="cookie-policy-link" onClick={() => { this.setState({ showPolicyStuffs : true}) }}>{labels.cookiePolicyLink}</a>
                <button className={"btn primary-buttom agree"} onClick={this.setCookie}>{labels.cookieAcceptButton}</button>
                <button className="btn btn-secondary " onClick={this.cancel}>{labels.cookieCancelButton}</button>
            </div>;
        return (element);
    }
}

