import React, { Component } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { Table } from 'reactstrap';

export class Catalog extends Component {

    constructor(props) {
        super(props);
        this.goTo = (category) => {
            this.props.history.push(`/catalog/${category}`);
        };

        this.convertToArrayNavLink = () => {
            const { labels, categories, match } = this.props;
            const category = match.params.category;
            const arr = [];
            for (let prop in categories) {
                arr.push(<NavItem key={`/catalog/${prop}-nav`} className={`side-link ${category === prop ? 'active' : ''}`}>
                    <a onClick={() => this.goTo(prop)} className="nav-link "><b>{categories[`${prop}`]}</b></a>
                </NavItem>);
            }

            return arr;
        };

        this.openDetails = (category, id) => {
            const { catalog } = this.props;
            const item = catalog.find(x => x.id == id);
            this.itemCatalog= item;
            this.props.history.push(`/details/${category}/${id}`);
        };

        this.getCurrentCategoryName = () => {
            const { labels, categories, match } = this.props;
            const category = match.params.category;
            return `${labels['Catalog']}-${categories[category.toString()]}`;
        }

        this.filterCatalog = () => {
            const { labels, categories, match, catalog, searchText } = this.props;
            const category = match.params.category;
            const filtered = catalog.filter(x => x.category === category);
            const filteredWithSearch =
               /* searchText && searchText.length >= 3 ? filtered.filter(x => x.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
                    || x.shortPrice.toLowerCase().indexOf(searchText.toLowerCase()) > -1 || x.shortDescription.toLowerCase().indexOf(searchText.toLowerCase()) > -1) :*/ filtered;
            return filteredWithSearch.map((x, i) => <div key={`${x.category}-${i}`} className="box-catalog" onClick={() => { this.openDetails(category, x.id)} }>
                <div className="orange-background">
                    <span ><strong>{x.shortName}</strong></span>
                </div>
                {x.image ? <div className="img-div">
                    <img style={{ maxHeight: '200px', maxWidth: '245px' }} src={`/content/images/${x.image}`} />
                </div> : null}
                <div>
                    <div>{x.shortPrice}</div>
                    <div></div>
                </div>
            </div>);
            //return filtered.map((x, i) => <tr key={`${x.category}-${i}`}>

            //    <td>{x.image.replaceAll(' ', '') ? <img style={{ maxHeight: '150px', maxWidth: '150px' }} src={`/content/images/${x.image}`} /> : null}</td>
            //    <td><div className="item-name"><strong>{x.name}</strong></div><div className="item-description">{x.notes ? x.notes.split('\n').filter(p => p).map((s, i) => <p key={`${s}-${i}`}>{s}</p>) : null}</div></td>
            //    <td><div className="item-description">{x.pricings ? x.pricings.split('\n').filter(p=>p).map((s, i) => i === 0 ? <p key={`${s}-${i}`}><strong>{s}</strong></p>:<p key={`${s}-${i}`}>{s}</p>) : null}</div></td>
            //</tr>);
        };
    }



    render() {
       
        const data = this.filterCatalog();
        return (
            <div>
                <h2>{this.getCurrentCategoryName()}</h2>
                
                
                <div className="catalog-container">
                    <Table responsive borderless>
                        <tbody>
                            <tr>
                                <td className="td-nav">
                                <Nav vertical className="categories-nav" style={{ maxHeight: `${68 * this.convertToArrayNavLink().length}px`, marginLeft:'-10px' }}>
                                    {this.convertToArrayNavLink()}
                                </Nav>
                            </td>
                                <td>
                                    <div className="table-container">
                                        {data}
                                    </div>
                            </td>
                            </tr>
                        </tbody>
                    </Table>
                    
                </div>
               
                    
                

            </div>
        );
    }
}
