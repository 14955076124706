import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
export class SearchPopup extends Component {
    state = {};
    filterItems = () => {
        const { categories } = this.props;
    };

    goTo = (category, id) => {
        this.props.history.push(`/details/${category}/${id}`);
    };
    render() {
        const { labels, match, catalog, position, searchText, changeSearch } = this.props;
        const filtered = catalog.filter(x => x.category.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
            x.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            || x.shortDescription.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            || x.shortName.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            || x.shortPrice.toLowerCase().indexOf(searchText.toLowerCase()) > -1);
        if (!filtered || filtered && !filtered.length) return (null);
        return (<div className="popconfirm-popover shadow-2" data-popper-placement="top"
            style={{ backgroundColor:'white', position: 'absolute', top: `${position.bottom}px`, left: `${position.left}px`, width: `837px`, margin: `0px`, zIndex:99999 }} >
            <div className="popconfirm fade show"><Table responsive borderless>
                {/*<thead className="orange-background usual-color">
                    <tr style={{ textAlign: 'center' }}>
                        <th style={{ width: '160px' }}>{labels['NameOfTool']}</th>
                        <th style={{ width: '330px' }}>{labels['toolDescription']}</th>
                        <th>{labels['toolPrice']}</th>
                        <th>{labels['toolDeposit']}</th>
                    </tr>
                </thead>*/}
                <tbody>{filtered.map((item, i) => <NavLink key={`${item.id}-${i}`} tag={Link} style={{ width: '837px' }} to={`/details/${item.category}/${item.id}`}>
                    < tr  className="usual-color" style={{ textAlign: 'center' }} onClick={() => { changeSearch('') }}>
                    <td style={{ width: '160px' }}>{item && item.name ? item.name.split('\n').filter(p => p).map((s, i) => <p key={`${s}-${i}`}>{s}</p>) : null}</td>
                    <td style={{ width: '330px' }}>{item && item.shortDescription ? item.shortDescription.split('\n').filter(p => p).map((s, i) => <p key={`${s}-${i}`}>{s}</p>) : null}</td>
                    <td>{item && item.pricings ? item.pricings.split('\n').filter(p => p).map((s, i) => <p key={`${s}-${i}`}>{s}</p>) : null}</td>
                    <td>{item && item.deposit ? item.deposit.split('\n').filter(p => p).map((s, i) => <p key={`${s}-${i}`}>{s}</p>) : null}</td>
                </tr></NavLink>)}</tbody>
            </Table>
                
        </div></div>);
    }
}